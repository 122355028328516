import { apiClient } from "@/api";
import { getAllPages } from "@/api/utils";
import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import talent from "./talent";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: "AppLayout",
    appTitle: process.env.VUE_APP_TITLE,
    appVersion: process.env.VUE_APP_VERSION,
    competences: [],
    tracks: [],
    compTracks: {},
    groups: {},
  },
  mutations: {
    SET_COMPETENCES(state, items) {
      state.competences = items;
    },
    SET_TRACKS(state, items) {
      state.tracks = items;
    },
    PATCH_COMPETENCE(state, competence) {
      const idx = state.competences.findIndex((n) => n.id === competence.id);
      console.log("idx, competence", idx, competence);
      if (idx === -1) return;
      const updateItem = {
        ...state.competences[idx],
        ...competence,
      };
      Vue.set(state.competences, idx, updateItem);
    },
    ADD_COMPETENCE(state, competence) {
      state.competences.unshift(competence);
    },
    DELETE_COMPETENCE(state, id) {
      const idx = state.competences.findIndex((n) => n.id === id);
      if (idx === -1) return;
      state.competences.splice(idx, 1);
    },
    SET_COMP_TRACKS(state, payload) {
      Vue.set(state.compTracks, payload.competenceId, payload.tracks);
    },
    SET_GROUPS(state, payload) {
      state.groups = payload;
    },
    PATCH_GROUP(state, group) {
      Vue.set(state.groups, group.id, {
        ...state.groups[group.id],
        ...group,
      });
    },
  },
  actions: {
    async getAllCompetence({ commit }) {
      const items = await getAllPages(apiClient, {
        method: "GET",
        url: "/competences",
        params: {
          limit: 30,
          offset: 0,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      commit("SET_COMPETENCES", items);
    },
    async getAllTracks({ commit }) {
      const items = await getAllPages(apiClient, {
        method: "GET",
        url: "/tracks",
        params: {
          limit: 30,
          offset: 0,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      commit("SET_TRACKS", items);
    },

    async getAllGroups({ commit, state }) {
      if (Object.keys(state.groups).length) return;

      const items = await getAllPages(apiClient, {
        method: "GET",
        url: "/competences/groups",
        params: {
          limit: 30,
          offset: 0,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const groups = items.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {});

      commit("SET_GROUPS", groups);
    },
    /**
     * Получает список треков компетенции
     */
    async getCompTracks({ commit }, competenceId) {
      const { data } = await apiClient({
        method: "GET",
        url: `competences/${competenceId}/tracks`,
        params: {
          limit: 50,
          offset: 0,
        },
      });
      commit("SET_COMP_TRACKS", {
        competence: competenceId,
        tracks: data.results,
      });
    },

    async deleteGroup({ commit, state }, id) {
      await apiClient({
        method: "DELETE",
        url: `competences/groups/${id}`,
      });
      const groups = { ...state.groups };
      delete groups[id];
      commit("SET_GROUPS", groups);
      const competences = [...state.competences].map((n) => {
        if (n.group_id === id) {
          return {
            ...n,
            group_id: null,
          };
        }
        return n;
      });
      commit("SET_COMPETENCES", competences);
    },

    async createGroup({ commit, state }, group) {
      const { data } = await apiClient({
        method: "POST",
        url: `/competences/groups`,
        data: group,
      });
      commit("SET_GROUPS", {
        ...state.groups,
        [data.id]: data,
      });
    },

    async updateGroup({ commit }, group) {
      const { data } = await apiClient({
        method: "PATCH",
        url: `/competences/groups/${group.id}`,
        data: group,
      });
      commit("PATCH_GROUP", data);
    },
  },
  getters: {
    competenceById: (state) => (id) => {
      const competence = state.competences.find((n) => n.id === id);
      if (!competence) return;
      const tracks = state.compTracks[id];
      return {
        ...competence,
        tracks: tracks,
      };
    },
  },
  modules: {
    user,
    talent,
  },
});
